

import Vue from 'vue';

import { mapActions } from 'vuex';

import { usersActions } from '@/store/modules/users/names';

export default Vue.extend({
    name: 'LoginAsLink',

    data() {
        return {
            userId: this.$route.params.id,
            process: false,
        };
    },

    methods: {
        ...mapActions({
            loginAsLink: usersActions.loginAsLink,
        }),

        async getLoginAsToken() {
            this.process = true;
            await this.loginAsLink({
                id: this.userId,
            }).then((res) => {
                this.process = false;
                window.open(res.loginAsLink.link, '_blank');
            });
        },
    },
});
