
import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import { donationsActions } from '@/store/modules/donations/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'DonationTable',

    props: { 
        donationId: {
            type: String,
            default: null,
        }, 
    },

    data() {
        return {
            sort: {
                sortDesc: [],
                sortBys: [],
            },
            status: [
                {
                    id: 10,
                    title_cat: 'New',
                },

                {
                    id: 20,
                    title_cat: 'Waiting for confirmation',
                },

                {
                    id: 30,
                    title_cat: 'Confirmed',
                },      
                
                {
                    id: 99,
                    title_cat: 'Failed',
                },                                            
            ],
            donation_type: [
                { title_cat: 'Free' }, 
                { title_cat: 'Matrix' }, 
                { title_cat: 'Matrix Replace' },
            ],
            sortObj: {
                statuses: [],
                donation_type: [],
            },
            dialog: false,
            dates: [],
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            donOptions: {
                created_at_from: '',
                created_at_to: '',
                search: '',
                statuses: [],
                page: 1,
                size: 10,
            },
            loading: false,
            footerProps: {
                'items-per-page-options': [2, 5, 10, 15],
            },
            headers: [
                { text: 'Sender id', align: 'left', value: 'sender_id' },
                { text: 'Sender', value: 'sender_name', align: 'center' },
                { text: 'Amount', value: 'amount', align: 'center' },
                { text: 'Created at', value: 'created_at', align: 'center' },
                { text: 'Recipient_id', value: 'recipient_id', align: 'center' },
                { text: 'Recipient', value: 'recipient_name', align: 'center' },
                { 
                    text: 'Donation Type',
                    value: 'donation_type',
                    align: 'center',
                    sortable: false,
                },
                { text: 'Status', value: 'status', align: 'center', sortable: false },
            ],
        };
    },

    computed: {
        ...mapState<ComposedState>({
            donation: (state: ComposedState) => state.donations.donation,
            donationUser: (state: ComposedState) => state.donations.donationUser,
            donationTotal: (state:ComposedState) => state.donations.donationTotal,
        }),

        dateRangeText(): string {
            return this.dates.join(' ~ ');
        },

        donationArray(): any[] {
            if (this.donationId) {
                // @ts-ignore
                return this.donationUser;
            }

            // @ts-ignore
            return this.donation;
        },

        count(): number {
            // @ts-ignore
            return this.donationTotal;
        },
    },

    watch: {
        options: {
            deep: true,
            handler() {
                this.donOptions.page = this.options.page;
                this.donOptions.size = this.options.itemsPerPage;
            },
        },

        sort: {
            deep: true,
            handler() {
                this.reduceArr();
            },
        },

        sortObj: {
            deep: true,
            handler() {
                this.getDonationApi({ ...this.donOptions, ...this.sortObj });
            },
        },

        donOptions: {
            deep: true,
            handler() {
                this.getDonationApi({ ...this.donOptions, ...this.sortObj });
            },
        },
    },

    async mounted() {
        if (!this.donOptions.created_at_from) {
            let today: Date | string = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();

            today = `${yyyy}-${mm}-${dd}T00:00:00.000000`;

            /**
             * Для простоты тестирования захардкодил 
             * (по тз должно показывать только транзакции за день), снизу рабочий вариант
             * this.donOptions.created_at_from = `${today}T00:00:00.000000` ;
             */
            this.donOptions.created_at_from = '2000-01-01T00:00:00.000000';
            this.donOptions.created_at_to = '2030-01-01T00:00:00.000000';
        }
        await this.getDonationApi({ ...this.donOptions, ...this.sortObj });
    },

    methods: {
        ...mapActions({
            getDonation: donationsActions.getDonation,
        }),

        async reduceArr() {
            await this.$nextTick();
            const arrAscDesc = this.sort.sortDesc.map((item) => {
                if (item === false) {
                    return 'asc';
                }
                return 'desc';
            });

            const reducedSortObj = this.sort.sortBys.reduce((acc, n, i) => ({
                ...acc,
                [n]: arrAscDesc[i],
            }), {});
            
            const currentStatuses = this.sortObj.statuses;
            const currentDonationType = this.sortObj.donation_type;

            this.sortObj = {
                ...reducedSortObj,
                statuses: currentStatuses,
                donation_type: currentDonationType,
            };

            this.getDonationApi({ ...this.donOptions, ...this.sortObj });
        },

        async getDonationApi(params) {
            if (this.donationId) {
                await this.getDonation({ ...params, ['donationId']: this.donationId });
                return;                
            }
            
            await this.getDonation(params);
        },

        dateClick() {
            this.dialog = false; 
            this.donOptions.created_at_from = `${this.dates[0]}T00:00:00.000000`;
            if (this.dates.length === 2) {
                this.donOptions.created_at_to = `${this.dates[1]}T23:59:59.000000`;
                this.getDonationApi({ ...this.donOptions, ...this.sortObj });
                return;
            }
            this.donOptions.created_at_to = '2030-01-01T00:00:00.000000';
            this.getDonationApi({ ...this.donOptions, ...this.sortObj });
        },

        onFilterChange() {
            this.options.page = 1;

            this.donOptions.page = this.options.page;
            this.donOptions.size = this.options.itemsPerPage;

            this.getDonationApi({ ...this.donOptions, ...this.sortObj });
        },
    },
});
