
import Vue, { PropType } from 'vue';

import { mapActions, mapState } from 'vuex';

import { ComposedState } from '@/types';
import { User } from '@/store/modules/users/types';
import { usersActions } from '@/store/modules/users/names';

export default Vue.extend({
    name: 'UserEditSubscribe',

    props: {
        userInfo: {
            type: Object as PropType<User>,
            required: true,
        },
    },

    data() {
        return {
            editSubscribeDialog: false,
            datepickerModal: false,
            newSubcriptionPeriod: null as null | string,
            confirmSubscribeModal: false,
            isDisabledBtnChangeSubscribe: true,
            subscribeRules: [
                (v) => {
                    if (v) {
                        // @ts-ignore
                        this.isDisabledBtnChangeSubscribe = false;
                        return true;
                    }

                    // @ts-ignore
                    this.isDisabledBtnChangeSubscribe = true;
                    return 'Fill the fields!';
                },
            ],
            newDreamLimit: 0,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            loadedStatus: (state: ComposedState) => state.users.loadedStatus,
        }),
    },

    mounted() {
        this.newDreamLimit = this.userInfo.dreams_to_activate || 0;
    },

    methods: {
        ...mapActions({
            changeUserSubscribe: usersActions.changeSubscribe,
            fetchUser: usersActions.fetchUser,
        }),

        changeSubscribe() {
            this.confirmSubscribeModal = true;
        },

        async confirmSubscribe() {            
            // @ts-ignore
            const res = await this.changeUserSubscribe({
                id: this.userInfo.id,
                subscribe_till: this.newSubcriptionPeriod,
                dreams_to_activate: +this.newDreamLimit || 0,
            });

            if (res) {
                await this.fetchUser(this.userInfo.id);
            }

            this.confirmSubscribeModal = false;
            this.editSubscribeDialog = false;
            this.newSubcriptionPeriod = null;
        },
    },
});
