

// @ts-nocheck

import Vue from 'vue';

import { mapActions, mapMutations, mapState } from 'vuex';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import LangSelect from '@/components/helper/LangSelect.vue';

import { generalActions, generalMutations } from '@/store/modules/general/names';
import { usersActions } from '@/store/modules/users/names';

import { ComposedState } from '@/types';

export default Vue.extend({
    name: 'UserEdit',

    components: {
        LangSelect,
    },

    mixins: [validationMixin],

    validations: {
        form: {
            name: { required },
            surname: { required },
            language: { required },
            birthDate: { required },
            country: { required },
            isFemale: { required },
        },
    },

    props: {
        userInfo: {
            type: Object,
        },
        callback: {
            type: Function,
        },
    },

    data() {
        return {
            datepickerModal: false,
            updateUserModal: false,

            form: {
                id: this.userInfo.id,
                name: this.userInfo.name,
                surname: this.userInfo.surname,
                language: this.userInfo.language,
                birthDate: this.userInfo.birth_date,
                country: this.userInfo.country_id,
                isFemale: this.userInfo.is_female,
                phone: this.userInfo.phone ? this.userInfo.phone : '',
            },

            loading: true,

            phoneMaskSettings: {
                countryCode: null,
                isOpen: false,
                inFocus: false,
                isInvalid: false,
                formattedNumber: '',
            },
        };
    },

    computed: {
        ...mapState<ComposedState>({
            defaultCountry: (state: ComposedState) => state.users.defaultCountry,
            admin: (state: ComposedState) => state.admin.admin,
            countries: (state: ComposedState) => state.general.countries,
            uploadErrors: (state: ComposedState) => state.general.errors,
            upload: (state: ComposedState) => state.general.upload,
        }),

        nameErrors() {
            const errors: string[] = [];
            if (!this.$v.form.name?.$dirty) return errors;
            !this.$v.form.name.required && errors.push('Name is required.');
            return errors;
        },

        surnameErrors() {
            const errors: string[] = [];
            if (!this.$v.form.surname?.$dirty) return errors;
            !this.$v.form.surname.required && errors.push('Surname is required.');
            return errors;
        },

        languageErrors() {
            const errors: string[] = [];
            if (!this.$v.form.language?.$dirty) return errors;
            !this.$v.form.language.required && errors.push('Language is required.');
            return errors;
        },

        birthDateErrors() {
            const errors: string[] = [];
            if (!this.$v.form.birthDate?.$dirty) return errors;
            !this.$v.form.birthDate.required
                && errors.push('Birth Date is required.');
            return errors;
        },

        countryErrors() {
            const errors: string[] = [];
            if (!this.$v.form.country?.$dirty) return errors;
            !this.$v.form.country.required && errors.push('Country is required.');
            return errors;
        },

        isFemaleErrors() {
            const errors: string[] = [];
            if (!this.$v.form.isFemale?.$dirty) return errors;
            !this.$v.form.isFemale.required && errors.push('Is female is required.');
            return errors;
        },
        styleList() {
            return {
                border: 'none',
                borderRadius: '0',
                borderBottom: `1px solid ${
                    this.phoneMaskSettings.inFocus
                        ? '#153657'
                        : this.phoneMaskSettings.isInvalid && this.phoneMaskSettings.isOpen
                            ? 'red'
                            : '#949494'
                } !important`,
                width: '100%',
            };
        },
    },

    methods: {
        ...mapActions({
            updateUserInfo: usersActions.updateUserInfo,
            uploadReset: generalActions.uploadReset,
        }),

        ...mapMutations({
            resetErrors: generalMutations.RESET_ERRORS,
        }),

        async addUser() {
            this.$v.form.$touch();
            this.resetErrors();
            this.form.phone = this.form.phone.replaceAll(/[^\d]/g, '');
            if (!this.$v.form.$error) {
                await this.updateUserInfo(this.form).then(() => {
                    if (this.callback) {
                        this.callback();
                    }
                });

                if (this.upload.check) {
                    setTimeout(() => {
                        this.updateUserModal = false;

                        (this.$refs.addNewUser as HTMLFormElement).reset();
                        this.$v.form.$reset();

                        this.resetErrors();
                        this.uploadReset();
                    }, 800);
                } else {
                    this.uploadReset();
                }
            }
        },
        onInputPhoneHandler(
            a,
            { valid, nationalNumber, countryCallingCode },
        ) {
            this.phoneMaskSettings.formattedNumber
                = countryCallingCode && nationalNumber
                    ? `${countryCallingCode}${nationalNumber}`
                    : '';

            if (valid) {
                this.phoneMaskSettings.isInvalid = false;
                this.$refs.tel.$refs.input.blur();
                this.$emit('update:invalid', false);
            } else if (!valid && document.readyState === 'complete') {
                this.phoneMaskSettings.isInvalid = true;
                this.$emit('update:invalid', true);
            }
        },

        countryWasToggled({ dialCode }) {
            this.phoneMaskSettings.countryCode = dialCode;
        },

        onBlurHandler() {
            this.phoneMaskSettings.inFocus = false;
            if (this.phoneMaskSettings.formattedNumber) {
                this.$emit(
                    'update:value',
                    this.phoneMaskSettings.formattedNumber,
                );
            }
            this.$emit('blur');
        },
    },
});
